import { computed, inject } from '@angular/core';
import { UserState } from '../../../../src/app.interface';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { SnackMessageService } from '@origin8-web/core-ui/snack';
import { REPRESENTATIVE_LEVEL, UserAppointment, UserProfile } from 'common.interfaces';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { EMPTY, catchError, filter, map, pipe, retry, switchMap, timer } from 'rxjs';
import { AdvocateServiceGateway } from '../../../service-gateways/advocate/advocate.service-gateway';
import { tapResponse } from '@ngrx/operators';
import { isAppointmentComingUp } from './user.store.utils';
import { NotificationService } from '@origin8-web/core-ui/notification';
import { DateTime } from 'luxon';

const initialState: UserState = {
  userInfo: undefined,
  upcomingUserAppointments: [],
  nextWeekScheduledTime: [],
  nextWeekScheduledTimeFetched: false,
};

const TEN_SECONDS = 10000; /* in ms */
const FIVE_MINUTES = 300000; /* in ms */
const ONE_MINUTE = 60000; /* in ms */

export const UserStore = signalStore(
  {
    providedIn: 'root',
  },
  withState(initialState),
  withComputed((store) => {
    return {
      licensedStates: computed(() => store.userInfo()?.licensedStates ?? []),
      wtLicensedStates: computed(() => store.userInfo()?.wtLicensedStates ?? []),
      wtSkills: computed(() => store.userInfo()?.wtSkills ?? []),
      upcomingUserAppointmentsCount: computed(() => store.upcomingUserAppointments().length),
      nextAppointment: computed(() => store.upcomingUserAppointments()[0]),
      laterAppointments: computed(() => store.upcomingUserAppointments().slice(1)),
      advocateLevel: computed(() => store.userInfo()?.advocateLevel),
      isContactor: computed(() => store.userInfo()?.advocateLevel === REPRESENTATIVE_LEVEL.CONTACTOR),
    };
  }),
  withMethods(
    (
      store,
      advocateServiceGateway = inject(AdvocateServiceGateway),
      snackMessageService = inject(SnackMessageService),
      notificationService = inject(NotificationService),
    ) => {
      return {
        setUserInfo: (userInfo: UserProfile | null | undefined) => {
          patchState(store, { userInfo });
        },
        loadNextWeekScheduledTime: rxMethod<UserProfile | undefined | null>(
          pipe(
            filter((userInfo) => !!userInfo?.id),
            switchMap((userInfo) => {
              return timer(1, FIVE_MINUTES).pipe(map(() => userInfo));
            }),
            switchMap((userInfo) => {
              return advocateServiceGateway
                .getUserScheduledTimeForNextWeek(userInfo?.email as string)
                .pipe(retry({ count: 2, delay: TEN_SECONDS }));
            }),
            tapResponse({
              next: (nextWeekScheduledTime: Date[]) =>
                patchState(store, { nextWeekScheduledTime, nextWeekScheduledTimeFetched: true }),
              error: (e) => {
                console.error(e);
                snackMessageService.showError('An error occurred while retrieving the scheduled time for next week');
                return EMPTY;
              },
            }),
          ),
        ),
        loadUpcomingUserAppointments: rxMethod<UserProfile | undefined | null>(
          pipe(
            filter((userInfo) => !!userInfo?.id),
            switchMap((userInfo) => {
              return timer(1, FIVE_MINUTES).pipe(map(() => userInfo));
            }),
            switchMap((userInfo) => {
              return advocateServiceGateway
                .getUserAppointmentsByOwnerIdForToday(userInfo?.id as string)
                .pipe(retry({ count: 2, delay: TEN_SECONDS }));
            }),
            tapResponse({
              next: (upcomingUserAppointments) => patchState(store, { upcomingUserAppointments }),
              error: (e) => {
                console.error(e);
                snackMessageService.showError('An error occurred while retrieving the appointments');
                return EMPTY;
              },
            }),
          ),
        ),
        upcomingAppointmentNotification: rxMethod<UserAppointment | undefined>(
          pipe(
            filter((nextAppointment) => !!nextAppointment),
            switchMap((nextAppointment) => {
              return timer(1, ONE_MINUTE).pipe(
                map(() => isAppointmentComingUp(nextAppointment as UserAppointment)),
                filter((isAppointmentComingUp) => isAppointmentComingUp),
                switchMap(() => {
                  return notificationService.displayNotification('[Protec8] Upcoming appointment', {
                    body: `Your appointment at ${nextAppointment?.startDateTime.toLocaleString(
                      DateTime.TIME_SIMPLE,
                    )} is coming up.`,
                    data: {
                      appointment: nextAppointment,
                    },
                    onClick: ({ appointment }: { appointment: UserAppointment }) => {
                      advocateServiceGateway.openAppointmentInSf(appointment);
                    },
                    tag: `protec8-upcoming-appointment-${DateTime.now().toUnixInteger()}`,
                  });
                }),
                catchError(() => {
                  return EMPTY;
                }),
              );
            }),
          ),
        ),
      };
    },
  ),
);
